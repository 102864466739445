body {
    overflow-x: hidden;
}

.main-menu {
  text-transform: uppercase;
  font-weight: 700;
}

.drupal-block {
  background-image: url('../images/drupal-logo-lg.png');
  background-repeat: no-repeat;
  background-position: bottom 10px right 10px;
  margin: 50px 0;
}


.featured-wrapper {
  border-top: 3px solid white;
  margin-top: -35px;
  margin-left: -10px;
  margin-right: -10px;
  transform: rotate(-2deg);
  .rtecenter {
    transform: rotate(2deg);
  }
}
