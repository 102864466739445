// Group leadership
.group-info-block {
	.field-name-field-group-leader {
		//font-size: 1.25em;
		font-weight: bold;
	}
	.field-name-field-group-assistants {
		//font-size: 100%;
		margin-bottom: 20px;
	}
	.view-footer p {
		margin: 0;
		padding: 0;
	}
}

// Group files

.group-files {
	th.views-field.views-field-title {
		@include media($laptop) {
			width: 300px;
		}
	}
}


// Group admin menus

/*
.group-admin-top {
	margin: 15px 0 0 0;
	background: $block-style-1-bg;
	border: 1px solid $base-border-color;
	border-width: 1px 1px 0 1px;
		h2 {
		margin: 0 0 10px 0;
		padding: 10px;
		background: $block-style-2-header;
		border-bottom: 1px solid $base-border-color;
		i {
			line-height: 0;
		}
	}
	ul {
		margin: 0;
	}
	li {
		display: inline-block;
		margin: 0 1em 1em 0 !important;
	}
	.contextual-links-wrapper li {
		display: block;
	}
}



.group-admin-bottom {
	//border: 1px solid $base-border-color;
	//border-width: 0 1px 2px 1px;
	margin-bottom: 30px;
	ul {
		margin: 0;
	}
	li {
		//display: inline-block;
		margin: 0 !important;
	}
	.contextual-links-wrapper li {
		display: block;
	}
}

*/

// Group content menu
.group-content-menu {
	.view-header {
		//border-bottom: 1px solid $base-border-color;
		p {
			display: none;
		}
		h3 {
			//margin: 0.8em 0;
		}
	}
	.views-row-1 {
		//margin-top: 20px;
	}
	.views-row {
		margin-bottom: 0;
	}
}

.group-info-block {
	.views-row {
		margin-bottom: 0;
	}
}

.group-admin-block {
	.admin-list {
		margin: 10px;
	}
	dd {
		display: none;
	}
	dt {
		margin: 0;
	}
	.view-header h3 {
		margin-bottom: 0;
	}
}

.group-posts {
	margin-top: 40px;
	border-top: 1px solid $base-border-color;
	padding-top: 20px;
}


.not-logged-in {
	.group-content-menu {
		.view-footer {
			display: none;
		}
	}
}


