//////////////////////////////////////////////////////////
// 1. Base menu styles
// 2. Main Menu
// 3. Mobile Menu
// 4. User Menu
// 5. Breadcrumbs
// 6. Nice Menu
// 7. OG Menu
//////////////////////////////////////////////////////////

// 1. Base menu styles

.horz-menu {
  ul.menu li {
    display: inline-block;
    //padding-right: 1em;
    margin: 0 0.5em;
  }
  ul.menu li:last-child,
  li:last-child {
    padding-right: 0;
  }
}

.horz-menu.left {
    margin-left: -0.5em;
}
.horz-menu.right {
    margin-right: -0.5em;
}

.horz-menu-buttons {
  ul.menu li {
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 1px solid grey;
    a {
      display: block;
      padding: 5px 20px;
    }
  }
}

//////////////////////////////////////////////////////////

// 2. Main Menu

.main-menu {
  ul {
    margin-right: -0.5em;
  }
  @include media($tablet) {
    font-size: 0.95em;
    position: relative;
    top: -10px;
  }
  @include media($desktop) {
    font-size: 1.3em;
    top: -5px;
  }
  @include media($highdef) {
    font-size: 1.5em;
    top: 0;
  }
	a:link,a:visited {
		color: $main-menu-link-color;
	}
	a:hover,a:active,a:focus {
		color: $main-menu-hover-color;
	}

}

//////////////////////////////////////////////////////////

// 3. Mobile Menu

/* Important - mean menu does not work without an expanded menu to call */
//#block-system-main-menu {
//    visibility: hidden;
//}

.mean-container .mean-bar {
  background: $header-color;
  background-image: url(../images/logo-mobile.svg);
  background-repeat: no-repeat;
  background-position: left;
  height: 80px;
  padding: 0;
  border-bottom: 1px solid $base-border-color;
  float: none;
}

.mean-container .mean-nav {
  background: $mobile-menu-bg-color;
  margin-top: 80px;
  font-size: 1em;
    font-family: $header-font;
  font-weight: 700;
}
.mean-container .mean-nav ul li a{
  color: $hamburger-color;
}

#hamburger span,
#hamburger span:before,
#hamburger span:after {
  cursor: pointer;
  border-radius: 2px;
  height: 5px;
  width: 35px;
  background: $hamburger-color;
  position: absolute;
  display: block;
  content: '';
  right: 10px;
  margin-right: 5px;
}
#hamburger span:before {
  top: 10px;
  right: 0;
  margin-right: 0;
}
#hamburger span:after {
  bottom: -20px;
  right: 0;
  margin-right: 0;
}

.mean-bar:before {
  content: "MENU";
  color: $hamburger-color;
  position: absolute;
  right: 14px;
  top: 45px;
  font-size: 80%;
}
.mean-nav ul li a.mean-expand:hover {
  background: $main-menu-hover-color !important;
}

a.mean-expand:hover {
  background: $main-menu-hover-color;
}


//////////////////////////////////////////////////////////

// 4. User Menu

.user-menu {
  margin: 7px 0;
  font-size: 80%;

	a:link,a:visited {
		color: $user-menu-link-color;
	}
	a:hover,a:active,a:focus {
		color: $user-menu-hover-color;
	}
}




//////////////////////////////////////////////////////////

// 5. Breadcrumbs

.breadcrumb {
  margin: 20px 0 10px;
  a:after {
    content: '»';
    padding: 0 10px;
  }

a:last-child:after {
      //content: '';
    }
}

//////////////////////////////////////////////////////////

// 6. Nice Menu

// Variables
$nice-sub-menu-width: 18em; // Sets variable for sub menu width
$nice-sub-menu-offset: 36px; // Defines vertical offset for sub menu. This may need adjusting if default font size or padding is changed.


.nice-main-menu {
  @include equal-width-horz-list-items;
  ul.nice-menu  {
    text-transform: uppercase;
    min-width: 100%;
  }
  ul.nice-menu li {
    border: 0;
    border-right: 1px solid $main-menu-link-color;
    background-color: $main-menu-wrapper-color;
    text-align: center;
    width: 100%;
    min-width: 90px; // Important IE8 fix
    @include media($phablet) {
      font-size: 14px;
    }
    @include media($desktop) {
      font-size: 20px;
    }
  }

  ul.nice-menu li:hover {
    background: $main-menu-hover-color;
  }
  ul.nice-menu li:first-child {
    border-left: 1px solid $main-menu-link-color;
  }
  ul.nice-menu a {
    color: $main-menu-link-color;
  }
  ul.nice-menu-down a:hover {
    color: $main-menu-hover-link-color;
  }
  ul.nice-menu .active-trail a {
    color: $main-menu-hover-link-color;
    background: $main-menu-hover-color url("../images/nice-menu/arrow-down.png") right center no-repeat; /* LTR */
  }
  ul.nice-menu ul {
      top: $nice-sub-menu-offset;
  }


  // Sub menu items
  ul.nice-menu-down ul li,
  ul.nice-menu-down ul li:hover {
    text-align: left;
    font-size: 16px;
    text-transform: capitalize;
    border-top: 0;
    border-bottom: 1px solid $main-menu-link-color;
    border-left: 1px solid $main-menu-link-color;
    margin-left: -1px;
  }

  ul.nice-menu-down .active-trail ul a {
    color: $main-menu-link-color;
    background: $main-menu-wrapper-color;
  }
  ul.nice-menu-down .active-trail ul a:hover {
    color: $main-menu-hover-link-color;
    background: $main-menu-hover-color;
  }


  ul.nice-menu-down li.menuparent {
      background: $main-menu-wrapper-color url("../images/nice-menu/arrow-down.png") right center no-repeat; /* LTR */
  }

  ul.nice-menu-down li.menuparent:hover {
    background: $main-menu-hover-color  url("../images/nice-menu/arrow-down.png") right center no-repeat; /* LTR */
  }

  ul.nice-menu-down li li.menuparent {
    background: $main-menu-wrapper-color url("../images/nice-menu/arrow-right.png") right center no-repeat; /* LTR */
  }

  ul.nice-menu-down li li.menuparent:hover, {
    background: $main-menu-wrapper-color  url("../images/nice-menu/arrow-right.png") right center no-repeat; /* LTR */
  }

  @for $i from 1 through 20 {
      ul.nice-menu-down ul li:first-child:nth-last-child(#{$i}),
      ul.nice-menu-down ul li:first-child:nth-last-child(#{$i}) ~ li {
      width: $nice-sub-menu-width;
    }
  }
  // Sub-sub menu
  ul.nice-menu-down li ul li ul {
    left: $nice-sub-menu-width;
    margin-top: -37px;
    margin-left: -1px;
  }

  // Tertiarry items
  ul.nice-menu ul ul{
      top: -6px;
  }

}//////////////////////////////////////////////////////////





