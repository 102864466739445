@import "../base/mixins";

.boxshadow {
  @include boxshadow;
}

.border {
  @include border;
  padding: 10px;
  margin-bottom: 30px;
}


.block-style-1 {
   margin: 15px 0 30px;
	h2 {
		margin: 0 0 10px 0;
		padding: 10px;
		background: $block-style-1-header-bg;
		color: $block-style-1-header-color;
		border-width: 1px solid $cerise;
    border-radius: 5px;
    @include boxshadow;
	}
}

.block-style-2 {
   margin: 15px 0 30px;
   border: 1px solid $cerise;
   border-width: 1px;
   border-radius: 5px;
   background: $block-style-2-bg;
   @include boxshadow;
   h1 {
   	display: none;
   }
	h2 {
		margin: 0 0 10px 0;
		padding: 10px;
		color: $block-style-2-header-color;
		background: $block-style-2-header-bg;
		i {
			line-height: 0;
		}
	}

	p,ul,ol,
	.view {
		padding: 0 10px 10px;;
    margin-bottom: 0;
	}
	.view-footer p {
		padding: 0;
	}
	.views-field-mail p {
		padding: 0;
	}
	li {
		margin-bottom: 0.5em;
	}
  .tagclouds {
    margin-bottom: 10px;
  }
  .button-style-1,
  .button-style-2 {
    margin-left: 10px;
  }
}

.well {
  background: $well-bg;
  padding: 10px;
  margin-bottom: 30px;
  @include border;
}

#preface .block-style-2 {
	margin: 30px 0 15px;
}


// Buttons
.button-style-1 {
  @include button-style-1;
}

.button-style-2 {
  @include button-style-2;
}


// Calendar tweaks

.button-style-2.right {
  margin-top: 0px;
}
.view .date-nav-wrapper .date-prev,
.view .date-nav-wrapper .date-next {
  background: white;
}
.view .date-nav-wrapper .date-prev a {
  margin-left: 0;
  margin-right: 5px;
  color: white;
}
.view .date-nav-wrapper .date-next a {
  margin-right: 0;
  color: white;
}


