//////////////////////////////////////////////////////////
// 1. Cover Art
// 2. Flexslider
// 3. Flexslider overrides
// 4. Beans
// 5. Inline Images
//////////////////////////////////////////////////////////



// 1. Cover art tweaks

.cover-art,
.video-cover-art,
.teaser-thumbnail {
	@include boxshadow;
}
.cover-art,
.video-cover-art {
	margin-bottom: 30px;
	li {
		list-style-type: none;
	}
}


// Set conditions for zoom image and hide bullets from lists
.field-name-field-youtube-video,
.field-name-field-image,
.field-name-field-slideshow-image,
.field-name-field-gallery-image {
	overflow: hidden;
	line-height: 0;
	li {
		list-style-type: none;
	}
}

.node-page.node-teaser,
.node-article.node-teaser,
.node-event.node-teaser,
.node-section.node-teaser,
.gallery-tile {
	@include imgzoom;
}


//////////////////////////////////////////////////////////

// 2. Flexslider

.flexslider {
	margin: 0 0 40px 0 !important;
	img {
		@include boxshadow;
		@include border;
	}
}


.front-page-slideshow {
	margin-bottom: 0;
	.flexslider {
		margin: 0 0 40px 0;
	}
}


// Corrects another class in typography
.flexslider,
.card,
.tile {
  ul,
  ol {
    margin-bottom: 0;
  }
}

// ////////////////////////////////////////////////////////

// 3. Flexslider Overrides

.flexslider {
	margin: 0 0 60px;
	 background: #fff;
	 border: 0px solid #fff;
	 position: relative;
	 -webkit-border-radius: 0px;
	 -moz-border-radius: 0px;
	 -o-border-radius: 0px;
	 border-radius: 0px;
	 -webkit-box-shadow: 0 0px 0px rgba(0,0,0,.2);
	 -moz-box-shadow: 0 0px 0px rgba(0,0,0,.2);
	 -o-box-shadow: 0 0px 0px rgba(0,0,0,.2);
	 box-shadow: 0 0px 0px rgba(0,0,0,.2);
	 zoom: 1;
 }

/* FlexSlider Defaults
********************************
.flexslider {
	margin: 0 0 60px;
	 background: #fff;
	 border: 4px solid #fff;
	 position: relative;
	 -webkit-border-radius: 4px;
	 -moz-border-radius: 4px;
	 -o-border-radius: 4px;
	 border-radius: 4px;
	 -webkit-box-shadow: 0 1px 4px rgba(0,0,0,.2);
	 -moz-box-shadow: 0 1px 4px rgba(0,0,0,.2);
	 -o-box-shadow: 0 1px 4px rgba(0,0,0,.2);
	 box-shadow: 0 1px 4px rgba(0,0,0,.2);
	 zoom: 1;
 }

*/





//////////////////////////////////////////////////////////

// 4. Bean images

.entity-bean img {
	height: auto;
}

//////////////////////////////////////////////////////////

// 5. Inline Images

img[style*="left"]  {margin:8px 20px 10px 0;}
img[style*="right"] {margin:8px 0 10px 20px; }


img.attr__format__media_large,
img.attr__format__media_default {
	height: auto !important;
}



.media-element img {
	border:	5px solid red;
}


