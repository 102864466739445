// Activity page promo well
.activity-promo {
	li:first-child {
		@include button-style-1;
		margin-right: 10px;
	}
	li {
		@include button-style-2;
		margin-right: 0.5em;
	}
	li:last-child {
		margin-right: 0;
	}
	@include media($mm-mobile) {
		li {
			width: 100%;
		}
	}
}



// Registration node

// Settings tabs
.page-node-registrations {
	ul.tabs.secondary {
		li {
			display: inline-block;
			margin-right: 1em;
			margin: 1em 0.5em 1em 0;


		}
		a {
			border: 1px solid $grey;
			border-radius: 5px;
			padding: 0.5em 0.75em;
		}
	}
}
.node-type-activity-registration-paid ,
.node-type-activity-registration-free,
.node-type-activity-reg-custom-1,
.node-type-activity-reg-custom-2,
.node-type-activity-reg-custom-3,
.node-type-activity-reg-custom-4,
.node-type-activity-reg-custom-5,
.node-type-activity-reg-custom-6,
.node-type-activity-reg-custom-7,
.node-type-activity-reg-custom-8, {
	h2,h3 {
		font-size: 1.562em !important;
	}
	.date-display-range {
		display: inline-block;
	}
	.field-name-field-reg-activity-date {
		font-weight: bold;
		margin-bottom: 1em;
		@include media($desktop) {
			//font-size: 1.25em;
			font-size: 1.563em;
		}
	}
	.views-field-registration-status {
		margin-bottom: 1em;
	}
	.views-field-views-conditional,
	.views-field-views-conditional-1,
	.views-field-views-conditional-3 { // Already booked message
		color: green;
		list-style-type: none;
	}
	.views-field-views-conditional-2 {
		color: red;
		list-style-type: none;
	}

	.already-registered {
		ul {
			margin-bottom: 1em;
			border-left: 3px solid green;
			padding: 0 10px;
			li {
				list-style-type: none;
				margin: 10px 0;
			}
		}
		.field-name-field-reg-child {
			display: inline-block;
			margin: 0;
			&:not(:nth-last-child(2)):after {
				content: "and";
			}
		}
		.booking-created {
			color: $grey;
			font-size: 0.8em;
		}

		.view-footer a {
			@include button-style-1;
			//padding: 10px;
		}
		.view-header {
			margin-top: -7px;
			width: 150px;
			float: right;
			@include media($tablet) {
				width: 100%;
				float: none;
			}

			.dashboard-button,
			.activity-button {
				margin-bottom: 0;
				width: 140px;
			}
		}
	}
	.view-activity-quick-names {
		border: 1px solid $base-border-color;
		padding: 10px;
		margin-bottom: 2em;
		h2 {
			font-size: 1.25em;
			@include media($desktop) {
				font-size: 1.563em;
			}
		}
	}
	.field-text-with-summary-wrapper {
		border: 1px solid $base-border-color;
		padding: 10px;
		margin-bottom: 2em;
	}
	.field-field-activity-reg-instructions-wrapper {
		border: 1px solid $base-border-color;
		padding: 10px;
		margin-bottom: 2em;
	}
	.field-name-field-reg-booking-note {
		margin-bottom: 2em;
		font-weight: bold;
	}
	.registration-who-msg {
		display: none;
	}
	.field-name-field-price {
		color: #D50000;
		font-size: 1.563em;
	}
	.price_suffix p {
		font-size: 50%;
	}

	.field-name-body {
		margin-top: 20px;
	}


	.field-field-reg-activity-conditions-wrapper {
		margin-bottom: 2em;
		padding: 0.5em;
		border: 1px solid $base-border-color;
	}
	.field-name-field-reg-child {
		margin-bottom: 2em;
		.description {
			margin: 0.5em 0;
		}
	}

}

// Booking form

.field-registration-wrapper {
	margin-bottom: 2em;
	border: 1px solid $base-border-color;
	padding: 0.5em;
	padding-bottom: 0;
	background: whitesmoke;
		// Provide some touch friendly select inputs for booking form
		@include media($mobile) {
			input[type='checkbox'] {
			transform: scale(2);
			margin: 0.5em;
			}
		}

		@include media($laptop) {
			input[type='checkbox'] {
			transform: scale(1.2);
			margin: 0.3em;
			}
		}

		.field-name-field-reg-child .description {
			font-weight: bold;
		}

		#edit-count {
			font-weight: bold;
			font-size: 1.3em;
			width: 3em;
			text-align: center;
		}
		.form-wrapper {
			margin: 0;
		}
		.field-name-field-registration {
		.form-actions {
			border-top: 1px solid $base-border-color;
			text-align: right;
		}
		#edit-cancel {
			display: none;
		}
	}
}

// Edit registraiton
.page-registration {
	#registration-form {
		#edit-submit,
		#edit-delete {
			margin-right: 10px;
			border-radius: 5px;
		}
	}
}

// Activity dates view
.activity-dates-view,
.view-activity-listing {
	margin-bottom: 2em;
	caption {
		text-align: left;
		font-weight: bold;
		font-size: 1.25em;
	}

	.view-header {
		display: block;
	}
	.view-filter {
		display: block;
	}
	.views-exposed-form {
		display: block;
		background: $silver;
		.views-exposed-widgets {
			border: 1px solid $base-border-color;
			padding: 0 10px;
			.description {
				display: none;
			}
			.form-submit {
				@include button-style-2;
				margin-top: 25px;
			}
		}
		#edit-submit-activity-listing,
		#edit-reset {
		  margin-top: 30px;
		  margin-bottom: 10px;
		}
	}

	.view-grouping-header {
		font-size: 1.25em;
		margin: 1em 0 0.5em;
		font-weight: 700;
		@include  media($desktop) {
			font-size: 1.953em;
		}
	}
	.view-grouping-content h3 {
		font-size: 1em;
		margin: 0.5em 0;
		font-weight: bold;
		@include  media($desktop) {
			font-size: 1.563em;
		}
	}


	.date-display-range,
	.field-type-commerce-price {
		display: inline-block;
	}

//  Start table row
	@include zebra-rows;
	.views-row {
		@include media($desktop) {
			padding: 15px 5px
		}
	}
	.views-field-title {
		font-weight: bold;
		@include media($desktop) {
			font-weight: bold;
			display: inline-block;
			margin-right: 0.5em;
			min-width: 12em;
		}
	}
	.field-name-field-reg-activity-date {
		font-weight: normal;
		@include media($laptop) {
			display: inline-block;
			margin-right: 1em;
			min-width: 7em;
		}
	}
	.views-field-field-reg-activity-date-1 {
		font-weight: normal;
		@include media($laptop) {
			display: inline-block;
			margin-right: 1em;
			min-width: 9em;
		}
	}
	.views-field-registration-status {
		@include media($desktop) {
			display: inline-block;
		}
	}

	.views-field-nid {
		float: right;
		.button-style-2 {
			@include media($desktop) {
				margin-top: -6px;
		}
			margin-bottom: 0px;
		}
	}

	.views-field-state a {
		@include button-style-1;
			margin-top: 1px;
			font-size: 0.9em;
			//padding: 5px 10px !important;
	}

//  End table row

	.views-field-count {
		display: inline-block;
	}
	a.dashboard-button {
		@include media($tablet) {
			margin-top: 31px;
		}
	}
	a.activity-button {
		margin-top: -1px;
		@include media($mm-phablet){
			margin-top: 10px;
		}
	}

	.dashboard-button {
		float: none;
		margin: 10px;
		@include media($tablet) {
			float: right;
			margin-right: 10px;
		}
	}
	h3 {
		font-size: 1.5em;
		line-height: 1.7em;
		margin-top: 1em;
	}
}

.my-activity-bookings {
	.views-field-state {
		display: inline-block;
		margin-right: 1em;
	}
	.views-field-nid{
		display: inline-block;
		margin-right: 0;
	}
	.view-footer {
		margin-top: 1.5em;
	}
	.field-name-field-reg-activity-date {
		font-weight: normal;
		@include media($laptop) {
			min-width: 14.5em;
		}
	}
}


// Activity dates sidebar block
.activity-dates-block {
	.field-name-field-reg-activity-date  {
		font-size: 1em;
		margin-bottom: 0;
		width: 100%;
		max-width: 100%;
	}
	.views-field-views-field-title {
		width: 100%;
	}

	.views-field-registration-status {
		margin-bottom: 0;
		width: 100%;
	}
	@include zebra-rows;
	.date-display-range {
		display: inline-block;
	}
	.view-header p,
	.view-footer p {
		padding: 0;
		margin: 10px 0;
	}
}



.activity-bookings-sidebar {
	.date-display-range {
		display: inline-block;
	}
	.field-name-field-reg-activity-date,
	.views-field-title {
		//display: block;
		width: 100%;
	}
	.views-field-state,
	.views-field-nid {
		display: inline-block;
	}
	@include zebra-rows;
	.view-header {
		p {
			//margin: 0;
			padding: 0;
		}
	}
}

// User Dashboard

.activity-button,
.dashboard-button {
	@include button-style-2;
	color: $charcoal !important;
	@include media($mobile) {
		float: right;
		margin-left: 10px;
		text-align: center;
	}
	@include media($phablet) {
		float: right;
		margin-top: -7px;
		margin-right: 0px;
		max-width: 160px;
	}
}

.my-children-block {
	.views-row {
		display: inline-block;
		margin-right: 1em;
		text-transform: capitalize;
	}
	.view-footer p,
	.view-empty p {
		padding: 0;
	}
}

.my-bookings-block-table {
	caption {
		text-align: left;
		font-size: 1.25em;
		font-weight: bold;
	}
	th.views-field.views-field-nid {
		width: 5em;
	}
	.date-display-range {
		display: inline-block;
	}
}






