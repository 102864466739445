.form-submit {
	@include button-style-1;
}


/////////////////////////////////////////
// Feedback page

.feedback-view {
	.views-row {
		@include border;
		@include boxshadow;
		border-radius: 5px;
		padding: 10px;
		background: url('../images/quotemarks.png');
		background-repeat: no-repeat;
		background-position: top left;
		margin-bottom: 30px;
	}
	//.webform-long-answer,
	.views-field-value,
	.views-field-value-2 {
		margin-left: 50px;
		display: inline-block;
		//min-height: 50px;
	}
	.fivestar-default {
		padding: 10px 0;
		margin-left: 50px;
	}
	.views-field-edit-submission {
		display: inline-block;
		float: right;
		margin-top: -20px;
	}
}

.feedback-submit-button a {
	@include button-style-1;
}

.feedback-block {
	margin-top: 30px;
}


/////////////////////////////////////////
// Work placement application form

.webform-client-form {
	.webform-component-fieldset {
		padding: 10px;
	}
	.fieldset-description {
		font-weight: bold;
		margin-bottom: 20px;
	}
	@include media ($desktop) {
		.left-fieldset {
			@include span-columns(6);
			input {
				margin-bottom: 0;
			}
		}
		.right-fieldset {
			@include span-columns(6);
			@include omega();
			input {
				margin-bottom: 0;
			}
		}
	}
	.messages p {
		margin: 0;
	}
}

.webform-progressbar {
	margin-top: 20px;
	width: 99%;
}
.webform-progressbar-inner,
.webform-progressbar-outer  {
	height: 8px;
}
.webform-progressbar-page {
	width: 15px;
	height: 15px;
	margin: -12px -4px;
	border-radius: 15px;
}


/////////////////////////////////////////
// School enquiry form

.webform-client-form {
	.form-text.form-email {
		max-width: 100% ;
	}
	.form-type-radio {
		display: inline-block;
		margin-right: 10px;
	}
	label.option {
	    position: relative;
	    top: 3px;
	}
	.webform-component--your-position,
	.webform-component--timescales,
	textarea  {
		border: 1px solid #ddd;
		padding: 10px;
		border-radius: 3px;
	}
}



























