.hero-wrapper,
#content {
	.bean-hero-block {
		background-size: cover !important;
		position: relative;
		@include media($mobile) {
			height: 380px;
		}
		@include media($highdef) {
			height: 450px;
		}
		@include media($cinema) {
			height: 600px;
		}
		.content {
			@include outer-wrapper;
			color: $hero-text-color;
			h2 {
				margin-top: 1em;
				text-shadow: 0px 0px 5px rgba(150, 150, 150, 1);
				font-size: 1.953em;
				font-weight: 700;
				display: inline-block;
				@include media($tablet) {
					font-size: 2.8em;
				}
				@include media($laptop) {
					font-size: 3.5em;
				}
				@include media($desktop) {
					font-size: 5em;
					margin-top: 0.5em;
				}
				@include media($cinema) {
					font-size: 6em;
					margin-top: 1em;
				}
			}
			p {
				font-size: 1.708em;
				text-shadow: 0px 0px 2px rgba(150, 150, 150, 1);
				font-weight: 700;
				margin-left: -0.5em;
				@include media($tablet) {
					font-size: 2em;
				}

				@include media($desktop) {
					font-size: 2.5em;
				}
				@include media($highdef) {
					font-size: 3em;
				}
				@include media($cinema) {
					font-size: 3.5em;
				}
			}
			ul {
				margin-top: 1em;
				@include media($highdef) {
					margin-top: 3em;
				}
			}
			li {
				margin: 5px 5px;
				@include media($highdef) {
					margin: 5px 5px;
				}
				list-style-type: none;
				a {
					color: white;
					font-size: 2em;
				}
			}
		}
	}
}


.featured-wrapper,
#content  {
	.bean-hero-block {
		position: relative;
		margin-top: 10px;
		margin-bottom: 2em;
		@include media($mobile) {
			height: 340px;
			padding: 1em;
		}
		@include media($laptop) {
			height: 300px;
			padding: 2em;
		}
		.content {
			color: $hero-text-color;
			h2 {
				font-size: 2.441em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			p {
				font-size: 200%;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			ul {
				bottom: 2em;
			}
			li {
				@include button-style-1;
				display: inline-block;
				margin: 10px 10px 10px 0;
				a {
					color: white;
				}
			}
		}
	}
}


.preface-wrapper,
#content  {
	.bean-hero-block {
		position: relative;
		margin-top: 10px;
		margin-bottom: 2em;
		@include media($mobile) {
			height: 340px;
			padding: 1em;
		}
		@include media($laptop) {
			height: 300px;
			padding: 2em;
		}
		.content {
			color: $hero-text-color;
			h2 {
				font-size: 2.441em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			p {
				font-size: 200%;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			ul {
				bottom: 2em;
			}
			li {
				@include button-style-1;
				display: inline-block;
				margin: 10px 10px 10px 0;
				a {
					color: white;
				}
			}
		}
	}
}



#content  {
	.bean-hero-block {
		.content {
			color: $hero-text-color;
			h2 {
				font-size: 2.441em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			p {
				font-size: 200%;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			ul {
				margin-top: 0.5em;
			}
			li {
				@include button-style-1;
				display: inline-block;
				margin: 10px 10px 10px 0;
				a {
					color: white;
				}
			}
		}
		@include media($mobile) {
			height: 340px;
			padding: 0em 1em;
			font-size: 90%;
		}
		@include media($laptop) {
			height: 300px;
			padding: 0em 1em;
			font-size: 120%;
		}
	}
}



